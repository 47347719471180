import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h5>{`Conclusion and future plans`}</h5>
    <p>{`I was surprised how `}<strong parentName="p">{`easy`}</strong>{` it was to build this project with the help of the `}<strong parentName="p">{`internet`}</strong>{`.
It was fun to create something you can actually `}<strong parentName="p">{`touch`}</strong>{`, as I'm normally only creating web pages.
The goal to have some `}<strong parentName="p">{`nice lightings`}</strong>{` in a `}<strong parentName="p">{`mate box`}</strong>{` for parties was achieved, but I'm already
thinking further.`}</p>
    <p>{`It would be nice to be able to control the `}<strong parentName="p">{`lights`}</strong>{` with a smartphone `}<strong parentName="p">{`app`}</strong>{` to change the
`}<strong parentName="p">{`colours`}</strong>{` or make it react to the `}<strong parentName="p">{`music`}</strong>{` you are playing.
Maybe it would also be cool to have more `}<strong parentName="p">{`mate lights boxes`}</strong>{`, put them together and then use the
lights as `}<strong parentName="p">{`pixels`}</strong>{` to write some `}<strong parentName="p">{`text`}</strong>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      